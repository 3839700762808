export const environment = {
  production: true,
  baseUrl: 'https://api.prodelivery.pro',
  //baseUrl: 'https://weni-api.poc-demo.com',
  defaultauth: '',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};
